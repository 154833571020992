<template>
    <div class="no-data">
        <img :style="'width:' +  imgWidth  + 'px'" src="../../static/icon/no-data.png">
        <span>{{ title }}</span>
    </div>
</template>

<script>
    export default {
        name: "noData",
        props:{
            title:{
                type:String,
                default:'暂无内容'
            },
            imgWidth:{
                type:String,
                default:'88'
            }
        }
    }
</script>

<style scoped>
.no-data{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 100px 0;
}
.no-data span{
    margin-top: 24px;
    color: #999999;
}
</style>
