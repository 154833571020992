import request from "@/utils/request";

/**
 * 我的已购列表
 * @param {*} data 
 * @param {String} page || 页码
 * @param {String} pageSize || 页码大小
 * @param {String} sortProperty || 排序
 * @param {String} sortDirection || ASC(升序)=0 DESC(降序)=1
 * @param {String} type || Tag(话题)=1  Category(分类)=2  Article(文章)=4
 * @returns 
 */
export const getBuyLists = (data) => {
  return request({
    url: "/api/app/articleApp/purchased",
    method: "post",
    data: data,
  });
};